import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { PersistenceService } from 'src/app/auth/services/persistence.service';

@Injectable({
  providedIn: 'root',
})
export class UnAuthGuardService implements CanActivate {

  public readonly appRoutes = AppRoutes;

  constructor(
    private router: Router,
    private persistanceService: PersistenceService,
  ) {}

  public canActivate(): boolean | UrlTree {
    try {
      const token = this.persistanceService.loadToken();
      if (token) return this.router.createUrlTree(['/', this.appRoutes.Dashboard]);
      else return true;
    } catch (error) {
      return true;
    }
  }
}
