import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { SignOut, SignOutActions } from 'src/app/auth/store/actions/sign-out.actions';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';

@Injectable()
export class SignOutEffects {

  @Effect({ dispatch: false })
  public signOut = this.storeActions.pipe(
    ofType<SignOut>(SignOutActions.SIGNOUT),
    tap(() => {
      this.router.navigate(['/', AppRoutes.SignIn]),
      this.snackbarService.queueSnackBar('You have been logged out.', { duration: 3000 });
    }),
  );

  constructor(
    private storeActions: Actions,
    private router: Router,
    private snackbarService: SnackbarService,
  ) {}
}
