import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getSignInState = createSelector(
  getAuthState,
  state => state.signIn,
);

export const isSigningIn = createSelector(
  getSignInState,
  state => state.isSigningIn,
);

export const getSignInFormData = createSelector(
  getSignInState,
  state => state.formParams,
);

export const getSignInStateError = createSelector(
  getSignInState,
  state => state.error,
);
