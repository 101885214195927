import { Component, OnDestroy } from '@angular/core';
import { ActivationEnd, Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { routingAnimations } from './app.animations';
import { MetaTagService } from './services/meta-tag.service';

@Component({
  selector: 'mt-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routingAnimations,
  ],
})
export class AppComponent implements OnDestroy {
  private _ngDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private metaTagService: MetaTagService,
  ) {

    // https://github.com/angular/angular/issues/19420#issuecomment-417050238
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      map((event: any) => {
        let data = null;
        let route = event.snapshot.root;

        while (route) {
          data = route.data || data;
          route = route.firstChild;
        }
        return data;
      }),
      takeUntil(this._ngDestroy),
    ).subscribe(data => {
      this.metaTagService.updateTitleTag(data.title);
      this.metaTagService.updateDescriptionTag(data.description);
      this.metaTagService.updateOgUrlTag(data.url);
    });
  }

  public prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  public ngOnDestroy() {
    this._ngDestroy.next();
    this._ngDestroy.complete();
  }
}
