<div class="main-body main-body--light-azure">
  <div class="container">
    <mt-public-navbar></mt-public-navbar>
    <mt-main-section></mt-main-section>
    <mt-how-it-works-section></mt-how-it-works-section>
    <mt-features-section></mt-features-section>
    <mt-pricing-section></mt-pricing-section>
    <mt-faq-section></mt-faq-section>
    <mt-about-us-section></mt-about-us-section>
    <mt-public-footer></mt-public-footer>
  </div>
</div>