import { Component } from '@angular/core';
import { AppRoutes } from 'src/app/app/app.routes.misc';

@Component({
  selector: 'mt-about-us-section',
  templateUrl: './about-us-section.component.html',
  styleUrls: ['../home.component.scss', './about-us-section.component.scss'],
})
export class AboutUsSectionComponent {
  public readonly appRoutes = AppRoutes;
}
