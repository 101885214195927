import { Component } from '@angular/core';
import { AppRoutes } from 'src/app/app/app.routes.misc';

@Component({
  selector: 'mt-public-footer',
  templateUrl: './public-footer.component.html',
  styleUrls: ['./public-footer.component.scss'],
})
export class PublicFooterComponent {
  public appRoutes = AppRoutes;
  public currentDate = new Date();
}
