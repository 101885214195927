import { Component } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { getIsEU } from 'src/app/auth/store/selectors/user.selector';

@Component({
  selector: 'mt-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {

  public isEU: Observable<boolean>;
  public appRoutes = AppRoutes;

  constructor(public store: Store<AppState>) {
    this.isEU = this.store.pipe(select(getIsEU));
  }
}
