import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpInfoResponse } from 'src/app/client/models/users.models';
import {
  SignUpInfoEmployeeAction,
  SignUpInfoEmployeeActions } from '../actions/sign-up-info-employee.actions';

export interface SignUpInfoEmployeeState {
  isGettingInfo: boolean;
  employeeInfo: SignUpInfoResponse;
  error: MytitleError[];
}

const signUpInfoEmployeeInitialState: SignUpInfoEmployeeState = {
  isGettingInfo: false,
  employeeInfo: null,
  error: null,
};

export function signUpInfoEmployeeReducer(
  state = signUpInfoEmployeeInitialState,
  generic: Action,
): SignUpInfoEmployeeState {

  const action = generic as SignUpInfoEmployeeAction;

  switch (action.type) {
    case SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE: {
      return {
        isGettingInfo: true,
        employeeInfo: null,
        error: null,
      };
    }

    case SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE_SUCCESS: {
      return {
        ...state,
        isGettingInfo: false,
        employeeInfo: action.payload,
        error: null,
      };
    }

    case SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE_ERROR: {
      return {
        ...state,
        isGettingInfo: false,
        error: action.payload,
      };
    }

    default: return state;
  }
}
