// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { ForgotPasswordRequestParams } from 'src/app/client/users-service.client';
import { MytitleError } from '../../../client/models/client.models';

export enum ResetPasswordActions {
  RESET_PASSWORD = '[Auth] Reset Password',
  RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success',
  RESET_PASSWORD_ERROR = '[Auth] Reset Password Error',
}

export class ResetPassword implements Action {
  public readonly type = ResetPasswordActions.RESET_PASSWORD;
  constructor(public payload: ForgotPasswordRequestParams) { }
}

export class ResetPasswordSuccess implements Action {
  public readonly type = ResetPasswordActions.RESET_PASSWORD_SUCCESS;
}

export class ResetPasswordError implements Action {
  public readonly type = ResetPasswordActions.RESET_PASSWORD_ERROR;
  constructor(public payload: MytitleError[]) { }
}

export type ResetPasswordAction =
ResetPassword |
ResetPasswordSuccess |
ResetPasswordError;
