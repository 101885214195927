<section class="section how-it-works" id="how-it-works">
  <div class="section__heading">
    <div class="mat-subheading-2"
         scrollAnimate
         animationName="fadeInUp">How it works</div>
  </div>

  <div class="segment__box">
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInLeft">
      <img src="/assets/home/home_picture_4.svg" class="segment__item-icon">
      <img src="/assets/home/home_icon_1.svg" class="segment__item-zoom-icon">
      <div class="segment__item-title mat-subheading-1">Upload your work</div>
      <div class="segment__item-desc mat-body-1">Upload any kind of your intellectual property in digital format. Mytitle will encrypt the file before storing it on its servers.</div>
    </div>

    <div class="segment__item"
         scrollAnimate
         animationName="fadeInRight">
      <img src="/assets/home/home_picture_5.svg" class="segment__item-icon">
      <img src="/assets/home/home_icon_1.svg" class="segment__item-zoom-icon">
      <div class="segment__item-title mat-subheading-1">Blockchain generates timestamp</div>
      <div class="segment__item-desc mat-body-1">A digital fingerprint of the content (Hash) with timestamp is made and saved onto a blockchain. Also the encrypted content is saved in the Mytitle cloud storage - a disaster backup is created.</div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInLeft">
      <img src="/assets/home/home_picture_6.svg" class="segment__item-icon">
      <img src="/assets/home/home_icon_1.svg" class="segment__item-zoom-icon">
      <div class="segment__item-title mat-subheading-1">Get certificate, prove ownership</div>
      <div class="segment__item-desc mat-body-1">Certificate holds an owner's personal information,
        and the date and time the content was uploaded.
        With the Mytitle certificate it is possible to prove your ownership of the content anytime;
        even without Mytitle.com. A guide for extracting information out of the blockchain is included in every certificate.</div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInRight">
      <img src="/assets/home/home_picture_7.svg" class="segment__item-icon">
      <img src="/assets/home/home_icon_1.svg" class="segment__item-zoom-icon">
      <div class="segment__item-title mat-subheading-1">In case of legal dispute</div>
      <div class="segment__item-desc mat-body-1">Blockchain certificates are internationally valid and are not monopolised by any central authority.
        Therefore no political or economical power is able to change its records. Its validity in court stands unquestionable as a form of indirect evidence.</div>
    </div>
  </div>
</section>
