import { Inject, Injectable } from '@angular/core';
import { WINDOW } from './../utils/window-provider';

@Injectable({
  providedIn: 'root',
})
export class WindowService {

  constructor(@Inject(WINDOW) private window: Window) {}

  public getHostname(): string {
    return this.window.location.hostname;
  }
}
