import { NgModuleRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppState, HmrNodeModule } from '../states/models/app.models';

/* The method gets the initial state upon ngrx store bootstrap */
export function getInitialState(): AppState {
  const defaultInitialState = {} as AppState;
  return (hasWindow() && (window as any).initialNgrxState) || defaultInitialState;
}

function hasWindow(): boolean {
  return typeof window !== 'undefined';
}

export function isHotModuleReplacementActive(): boolean {
  return hasWindow() && !!(window as any).initialNgrxState;
}

export function ngrxStatePersistance<T>(
  bootstrap: () => Promise<NgModuleRef<T>>,
  module: HmrNodeModule,
) {
  let ngModuleRef: NgModuleRef<T>;

  // this handles situation when app is already loaded and hot module replacement occurs
  if (document.readyState === 'complete') {
    bootstrap().then(ref => ngModuleRef = ref);
  }

  // first load in hmr mode
  fromEvent(document, 'DOMContentLoaded')
    .subscribe(() => bootstrap().then(ref => ngModuleRef = ref));

  // tracks the current module
  module.hot.accept();
  // triggered when changes in the code
  module.hot.dispose(() => persistNgrxState(ngModuleRef));
}

function persistNgrxState<T>(ngModuleRef: NgModuleRef<T>) {
  const store: Store<AppState> = ngModuleRef.injector.get<Store<AppState>>(Store);
  const stateStore = store as Store<AppState>;
  saveNgrxStateInWindow(stateStore);
  // reference to the old module is destroyed
  ngModuleRef.destroy();
}

/* saves the store state to the window object */
function saveNgrxStateInWindow(store: Store<AppState>) {
  store.pipe(first()).subscribe(state => {
    (window as any).initialNgrxState = state;
  });
}
