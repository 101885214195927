import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MetaTagService {

  constructor(
    private title: Title,
    private meta: Meta,
  ) {}

  public updateTitleTag(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  public updateOgUrlTag(url: string) {
    let ogUrl = environment.apiUrl;
    if (url) ogUrl += `/${url}`;
    this.meta.updateTag({ property: 'og:url', content: ogUrl });
  }

  public updateOgTypeTag() {
    this.meta.updateTag({ property: 'og:type', content: 'article' });
  }

  public updateOgImageTag(imageUrl: string) {
    this.meta.updateTag({ property: 'og:image', content: imageUrl });
  }

  public updateDescriptionTag(description: string) {
    this.meta.updateTag({ property: 'og: description', content: description });
  }
}
