import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PersistenceService } from 'src/app/auth/services/persistence.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {

  constructor(private persistanceService: PersistenceService) {}

  public canActivate(): boolean {
    try {
      const token = this.persistanceService.loadToken();
      if (token) return true;
      else return false;
    } catch (error) {
      return false;
    }
  }
}
