import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import parseDomainName from '../utils/parse-domain-name';
import { WindowService } from './window.service';

@Injectable({
  providedIn: 'root',
})
export class DomainGuardService implements CanActivate {

  public readonly appRoutes = AppRoutes;

  constructor(
    private router: Router,
    private windowService: WindowService,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return of(this.windowService.getHostname()).pipe(
      map(name => !!parseDomainName(name)),
      map(isCompanyDomainPresent => {
        if (isCompanyDomainPresent) {
          return this.router.createUrlTree(['/', this.appRoutes.SignIn]);
        } else return true;
      }),
    );
  }
}
