import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { SignOut } from 'src/app/auth/store/actions/sign-out.actions';
import { getIsAuthorized } from 'src/app/auth/store/selectors/user.selector';

@Component({
  selector: 'mt-public-navbar',
  templateUrl: './public-navbar.component.html',
  styleUrls: ['./public-navbar.component.scss'],
})
export class PublicNavbarComponent implements OnDestroy {

  public isMobile: boolean;
  public isSmallScreen: boolean;
  public appRoutes = AppRoutes;
  public isLoggedIn: Observable<boolean>;

  private _ngDestroy = new Subject<void>();
  private _expanded = false;

  constructor(
    public store: Store<AppState>,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.isLoggedIn = this.store.select(getIsAuthorized);

    this.breakpointObserver
      .observe(['(max-width: 599px)', '(max-width: 768px)'])
      .pipe(takeUntil(this._ngDestroy))
      .subscribe(result => {
        this.isMobile = result.breakpoints['(max-width: 599px)'];
        this.isSmallScreen = result.breakpoints['(max-width: 768px)'];
      });
  }

  get expanded() {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = value;
  }

  public toggleNavbar() {
    this._expanded = !this._expanded;
  }

  public signOut() {
    this.store.dispatch(new SignOut());
  }

  public ngOnDestroy() {
    this._ngDestroy.next();
    this._ngDestroy.complete();
  }
}
