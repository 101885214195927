<section class="section pricing" id="pricing">
  <div class="section__heading"
       scrollAnimate
       animationName="fadeInUp">
    <div class="mat-subheading-2">Pricing</div>
  </div>

  <div class="pricing__card-box"
       scrollAnimate
       animationName="fadeInUp">
    <div class="mt-card mt-card--white pricing__card" [ngClass]="{'pricing__card--main-hidden': pricingExtended}">
      <div class="mt-card__content">
        <div class="pricing__card-text pricing__card-text--first mat-caption">
          <span></span>
          <p>
            Create and use company account with all employee accounts for <b>FREE</b>.
          </p>
        </div>
        <div class="pricing__card-text mat-caption">
          <span></span>
          <p>
            Buy upload credits and secure your digital content.
          </p>
        </div>
        <div class="pricing__card-text pricing__card-text--last mat-caption">
          <span></span>
          <p>
            Invite 3 employees and get <b>10 uploads</b> free of charge to discover our services.
          </p>
        </div>
      </div>
      <div class="mt-card__actions">
        <button class="mt-button mt-button--azure" [routerLink]="['/', appRoutes.CompanySignUp]">
          create company account
        </button>
      </div>
      <div class="mt-card__text-bellow mat-caption" (click)="pricingExtended = true">
        <span>
          Starting at 4.99{{ (isFromEU | async) ? '€' : '$' }} per upload &rarr;
        </span>
      </div>
    </div>

    <div class="mt-card mt-card--white pricing__card pricing__card--center" [ngClass]="{'pricing__card--secondary-hidden': !pricingExtended}">
      <div class="mt-card__content">
        <div class="pricing__card-contact mat-caption">
          To get full pricing list or even individual prices tailored to your company, contact us on <b>business@mytitle.com</b>
        </div>
      </div>
      <div class="mt-card__text-bellow mat-caption" (click)="pricingExtended = false">
        <span>
          &larr; Return back
        </span>
      </div>
    </div>
  </div>
</section>
