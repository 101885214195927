<section class="section about-us">
  <div class="section__heading mat-subheading-2"
       scrollAnimate
       animationName="fadeInUp">About us</div>
  <div class="about-us__content">
    <div class="about-us__text"
         scrollAnimate
         animationName="fadeInLeft">
      <p>We are a team with likely the highest average of years of experience for start-ups in Prague, Czech Republic.
        Our vision is to support the global creative community in protecting their intellectual property with modern
        technological solutions.
        Our service validates your completed work and can also be used to verify every step from concept to production.
      </p>
      <div class="about-us__button">
        <button class="mt-button mt-button--azure">
          <a class="about-us__link"
             rel="noreferrer"
             [routerLink]="['/', appRoutes.About]">
          Discover more about us
       </a>
        </button>
      </div>
    </div>
    <img src="/assets/home/home_picture_14.svg"
         class="about-us__picture"
         alt=""
         scrollAnimate
         animationName="fadeInRight">
  </div>
</section>