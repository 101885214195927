import { Injectable } from '@angular/core';
import {
  IndividualConfig,
  ToastrService,
} from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public readonly defaultConfig: Partial<IndividualConfig> = {
    tapToDismiss: true,
    timeOut: 7000,
    disableTimeOut: false,
    easeTime: 300,
    positionClass: 'toast-bottom-left',
  };

  constructor(private toastr: ToastrService) {}

  public showSuccess(message: string) {
    this.toastr.success(message, null, this.defaultConfig);
  }

  public showInfo(message: string) {
    this.toastr.info(message, null, this.defaultConfig);
  }

  public showError(message: string) {
    this.toastr.error(message, null, this.defaultConfig);
  }

  public showWarning(message: string) {
    this.toastr.warning(message, null, this.defaultConfig);
  }

}
