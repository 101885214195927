// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpEmployeeVerificationParams } from 'src/app/client/models/users.models';
import { TokenInfo } from '../models/token-info.models';

export enum SignUpEmployeeVerificationActions {
  SIGNUP_EMPLOYEE_VERIFICATION = '[Auth] Sign-up employee verification',
  SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS = '[Auth] Sign-up employee verification success',
  SIGNUP_EMPLOYEE_VERIFICATION_ERROR = '[Auth] Sign-up employee verification error',
}

export class SignUpEmployeeVerification implements Action {
  public readonly type = SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION;
  constructor(public payload: SignUpEmployeeVerificationParams) {}
}

export class SignUpEmployeeVerificationSuccess implements Action {
  public readonly type = SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS;
  constructor(public payload: TokenInfo) {}
}

export class SignUpEmployeeVerificationError implements Action {
  public readonly type = SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export type SignUpEmployeeVerificationAction =
  SignUpEmployeeVerification |
  SignUpEmployeeVerificationSuccess |
  SignUpEmployeeVerificationError;
