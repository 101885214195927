// tslint:disable-next-line:max-line-length
// https://github.com/trendster-io/ng-lazy-image/blob/master/projects/ng-lazy-image/src/lib/lazy-image.directive.ts
import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

export type AnimationName = 'fadeInLeft' | 'fadeInRight' | 'fadeInUp';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scrollAnimate]',
})
export class AnimationOnScrollDirective implements OnInit, OnDestroy {
  @Input() public animationName: AnimationName = 'fadeInUp';

  @Input() public root: Element;
  @Input() public rootMargin: string;
  @Input() public threshold: number | number[] = 0.7;

  // default visibility to false
  public isVisible = false;

  private intersectionObserver: IntersectionObserver;
  private intersectionObserverVisibility: IntersectionObserver;

  constructor(
    private el: ElementRef<Element>,
    private renderer: Renderer2,
  ) {
  }

  public ngOnInit(): void {
    this.addIntersectionObserver();
  }

  public ngOnDestroy(): void {
    if (this.intersectionObserver) {
      this.removeIntersectionObserver();
    }
  }

  private addIntersectionObserver(): void {
    // Check if window is defined to handle SSR, as well as check for IntersectionObserever
    // support in the browser
    if (this.isBrowserWithIntersectionObserver()) {
      this.registerForAnimation();
    }
  }

  private isBrowserWithIntersectionObserver(): boolean {
    return typeof window !== 'undefined' && 'IntersectionObserver' in window;
  }

  private registerForAnimation(): void {
    // Remove any previous observer if exists
    if (this.intersectionObserver) this.removeIntersectionObserver();

    this.intersectionObserver = new IntersectionObserver(
      entry => {
        // because there will only ever be one instance
        // of the element we are observing
        // we can just use entry[0]
        if (entry[0].isIntersecting) {
          this.addAnimationClass();
          this.removeIntersectionObserver();
        }
      },
      {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      },
    );
    this.intersectionObserver.observe(this.el.nativeElement);

    this.intersectionObserverVisibility = new IntersectionObserver(
      entry => {
        // because there will only ever be one instance
        // of the element we are observing
        // we can just use entry[0]
        if (entry[0].isIntersecting) {
          if (!this.isVisible) {
            this.setClass('not-visible');
          }
          this.removeIntersectionObserverVisibility();
        }
      },
      {
        root: this.root,
        // the 60px bottom margin makes the intersection event trigger sooner and thus set the
        // visibility to 'hidden', preventing a flickering effect
        rootMargin: '0px 0px 1000px 0px',
        threshold: 0,
      },
    );
    this.intersectionObserverVisibility.observe(this.el.nativeElement);
  }

  // utility function to mark element visible and add css class
  private addAnimationClass(): void {
    // mark this element visible, we won't remove the class after this
    this.isVisible = true;
    this.setClass('visible');
    // use default for animate.css if no value provided
    this.setClass(this.animationName);
  }

  // utility function to add one or more css classes to element in DOM
  private setClass(classes: string): void {
    for (const c of classes.split(' ')) {
      this.renderer.addClass(this.el.nativeElement, c);
    }
  }

  private removeIntersectionObserver() {
    this.intersectionObserver.disconnect();
    this.intersectionObserver = null;
  }

  private removeIntersectionObserverVisibility() {
    this.intersectionObserverVisibility.disconnect();
    this.intersectionObserverVisibility = null;
  }
}
