import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { getIsEU } from 'src/app/auth/store/selectors/user.selector';

@Component({
  selector: 'mt-pricing-section',
  templateUrl: './pricing-section.component.html',
  styleUrls: ['../home.component.scss', './pricing-section.component.scss'],
})
export class PricingSectionComponent {
  public readonly appRoutes = AppRoutes;
  public readonly isFromEU: Observable<boolean>;
  public pricingExtended = false;

  constructor(private store: Store<AppState>) {
    this.isFromEU = this.store.pipe(select(getIsEU));
  }
}
