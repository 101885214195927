import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpCompanyParams } from 'src/app/client/models/users.models';
import { SignUpCompanyAction, SignUpCompanyActions } from './../actions/sign-up-company.actions';

export interface SignUpCompanyState {
  formParams: SignUpCompanyParams;
  isSigningUp: boolean;
  isSignedUp: boolean;
  error: MytitleError[];
}

const signUpCompanyInitialState: SignUpCompanyState = {
  formParams: null,
  isSigningUp: false,
  isSignedUp: false,
  error: null,
};

export function signUpCompanyReducer(
  state = signUpCompanyInitialState,
  generic: Action,
): SignUpCompanyState {

  const action = generic as SignUpCompanyAction;

  switch (action.type) {
    case SignUpCompanyActions.SIGNUP_COMPANY: {
      return {
        error: null,
        isSigningUp: true,
        isSignedUp: false,
        formParams: action.payload,
      };
    }

    case SignUpCompanyActions.SIGNUP_COMPANY_SUCCESS: {
      return {
        ...state,
        isSigningUp: false,
        isSignedUp: true,
      };
    }

    case SignUpCompanyActions.SIGNUP_COMPANY_ERROR: {
      return {
        ...state,
        formParams: null,
        isSigningUp: false,
        error: action.payload,
      };
    }

    default: return state;
  }
}
