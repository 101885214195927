<section class="section features">
  <div class="section__heading"
       scrollAnimate
       animationName="fadeInUp">
    <div class="mat-subheading-2">Business features</div>
  </div>

  <div class="segment__box">
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInLeft">
      <img src="/assets/home/home_picture_8.svg" class="segment__picture" alt="">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Invite unlimited amount of employees</b>
        </div>
        <div class="segment__desc mat-caption">
          No matter how big your team is, everybody can have a secure access to the data they deserve.
        </div>
      </div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInRight">
      <img src="/assets/home/home_picture_9.svg" class="segment__picture" alt="">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Enjoy advanced user management</b>
        </div>
        <div class="segment__desc mat-caption">
          Grant permission for access either to single user or a group of employees. Make user groups, enjoy adding and extending internal system roles, organisational units, import/export functionality etc.
        </div>
      </div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInLeft">
      <img src="/assets/home/home_picture_10.svg" class="segment__picture" alt="">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Buy credits or use invoice system</b>
        </div>
        <div class="segment__desc mat-caption">
          Plan on-the-go and have your costs under control. Pay for a number of credits in advance or have a report generated each month. Assign credits for a specific period of time and be informed when the quota is reached.
        </div>
      </div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInRight">
      <img src="/assets/home/home_picture_11.svg" class="segment__picture" alt="">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Share with selected groups</b>
        </div>
        <div class="segment__desc mat-caption">
          Collaborate among user groups. Invite external co-workers. Share content with clients and consultants, let them comment and annotate.
        </div>
      </div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInLeft">
      <img src="/assets/home/home_picture_12.svg" class="segment__picture" alt="">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Upload whole folders into blockchain</b>
        </div>
        <div class="segment__desc mat-caption">
          Do not loose precious time by uploading files one by one. A new feature enables you to upload and stamp whole folders with a few clicks.
        </div>
      </div>
    </div>
    <div class="segment__item"
         scrollAnimate
         animationName="fadeInRight">
      <img src="/assets/home/home_picture_13.svg" class="segment__picture">
      <div class="segment__text">
        <div class="segment__title mat-body-1">
          <b>Manage all files and certificates</b>
        </div>
        <div class="segment__desc mat-caption">
          Oversee what is happening with your sensitive data, anytime. Review who shared it and who has an access to read it. Track file versions and its movement. Download activity history statements and monitor traffic over your files.
        </div>
      </div>
    </div>
  </div>
</section>
