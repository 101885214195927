import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/browser';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import { AuthModule } from '../auth/auth.module';
import { ConfirmationDialogModule,
} from '../partials/confirmation-dialog/confirmation-dialog.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { ConfirmationCodeDirective,
} from './directives/confirmation-code.directive';
import { HomeModule } from './home/home.module';
import { SentryErrorHandler } from './services/sentry-error-handler.service';
import { appEffects } from './states/app.effects';
import { appReducers, metaReducers } from './states/app.reducers';
import { RouterSerializer } from './states/router.serializer';
import { getInitialState } from './utils/ngrx-state-persistance';
import { windowProvider } from './utils/window-provider';

if (location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://f4bcd86c112043f2bf437cd7bc867f75@sentry.io/2608293',
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ConfirmationCodeDirective,
  ],
  imports: [
    AuthModule,
    HomeModule,
    CommonModule,
    ConfirmationDialogModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
    StoreModule.forRoot(appReducers, {
      metaReducers, initialState: getInitialState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      serializer: RouterSerializer,
      routerState: RouterState.Minimal,
    }),
    EffectsModule.forRoot(appEffects),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
  ],
  providers: [
    environment.production ? {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    } : [],
    windowProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
