import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { asyncScheduler, of, scheduled } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PersistenceService } from '../../services/persistence.service';
import {
  LoadTokenError,
  LoadTokenSuccess,
} from '../actions/loadToken.action';
import {
  RefreshTokenActions,
  RefreshTokenError,
  RefreshTokenSuccess,
} from '../actions/refreshToken.action';
import { SignInActions, SignInSuccess } from '../actions/sign-in.actions';
import { SignOut, SignOutActions } from '../actions/sign-out.actions';
import {
  SignUpCompanyVerificationActions,
  SignUpCompanyVerificationSuccess,
} from '../actions/sign-up-company-verification.actions';
import {
  SignUpEmployeeVerificationActions,
  SignUpEmployeeVerificationSuccess } from '../actions/sign-up-employee-verification.actions';

@Injectable()
export class PersistenceEffects {
  @Effect({ dispatch: false })
  public readonly persistTokenOnLogin = this.storeActions.pipe(
    ofType<
      SignUpCompanyVerificationSuccess |
      SignUpEmployeeVerificationSuccess |
      RefreshTokenSuccess |
      SignInSuccess>(
        SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_SUCCESS,
        SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS,
        RefreshTokenActions.REFRESH_TOKEN_SUCCESS,
        SignInActions.SIGNIN_SUCCESS),
    tap(action => {
      this.persistenceService.persistToken(action.payload);
    }),
  );

  @Effect({ dispatch: false })
  public readonly removeTokenOnRefreshTokenError = this.storeActions.pipe(
    ofType<RefreshTokenError | SignOut>(
      RefreshTokenActions.REFRESH_TOKEN_ERROR, SignOutActions.SIGNOUT),
    tap(() => {
      this.persistenceService.removeToken();
    }),
  );

  /**
   * Autoload persisted token from localStorage
   */
  @Effect()
  public readonly loadToken = scheduled(of(null), asyncScheduler).pipe(
    map(() => {
      try {
        return new LoadTokenSuccess(this.persistenceService.loadToken());
      } catch {
        return new LoadTokenError();
      }
    }),
  );

  constructor(
    private storeActions: Actions,
    private persistenceService: PersistenceService,
  ) {
  }

}
