// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';

export enum SignOutActions {
  SIGNOUT = '[Auth] Sign-out',
}

export class SignOut implements Action {
  public readonly type = SignOutActions.SIGNOUT;
}

export type SignOutAction = SignOut;
