<section class="section main">
  <div class="main__picture-box">
    <img src="/assets/home/home_picture_1.svg" class="main__picture" alt="">
  </div>

  <div class="main__text">
    <div class="main__heading mat-title">Protect your business</div>
    <div class="main__content mat-body-1 mt-4">
      <p>
        Even the tiniest piece of data can be misused.
        Every idea and innovation has its value.
        Each piece you produce deserves guarding.
        Everything digitally expressed can be protected.
      </p>
      <p>
        Right here. Right now.
      </p>
</div>
    <div class="main__button">
      <button class="mt-button mt-button--white" [routerLink]="appRoutes.CompanySignUp">
        <ng-container *ngIf="extraSmallScreen">Create account</ng-container>
        <ng-container *ngIf="!extraSmallScreen">Create company account for free</ng-container>
      </button>
    </div>
  </div>
</section>
