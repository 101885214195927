import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignInParams } from 'src/app/client/models/users.models';
import { SignInAction, SignInActions } from './../actions/sign-in.actions';

export interface SignInState {
  formParams: SignInParams;
  isSigningIn: boolean;
  error: MytitleError[];
}

const signInInitialState: SignInState = {
  formParams: null,
  isSigningIn: false,
  error: null,
};

export function signInReducer(
  state = signInInitialState,
  generic: Action,
): SignInState {

  const action = generic as SignInAction;

  switch (action.type) {
    case SignInActions.SIGNIN: {
      return {
        ...state,
        formParams: action.payload,
        error: null,
        isSigningIn: true,
      };
    }

    case SignInActions.SIGNIN_TWOFA: {
      return {
        ...state,
        isSigningIn: true,
        formParams:  {
          ...state.formParams,
          code: action.payload,
        },
      };
    }

    case SignInActions.SIGNIN_SUCCESS: {
      return {
        ...state,
        formParams: null,
        isSigningIn: false,
      };
    }

    case SignInActions.SIGNIN_ERROR: {
      return {
        ...state,
        isSigningIn: false,
        error: action.payload,
      };
    }

    case SignInActions.CLEAR_SIGNIN: {
      return {
        ...state,
        error: null,
      };
    }

    default: return state;
  }
}
