<header>
  <div class="wrapper">

    <a routerLink="/">
      <img src="/assets/icons/my-title-business-logo-azure.svg" class="wrapper__picture" alt="logo">
    </a>

    <nav class="wrapper__nav mt-nav" *ngIf="!isMobile">
      <a *ngIf="((isLoggedIn | async) === false) && !isSmallScreen"
         class="wrapper__nav-link"
         href="https://mytitle.com/">Individuals</a>
      <a *ngIf="(isLoggedIn | async) === false"
         class="wrapper__nav-link"
         routerLink="/"
         fragment="pricing">Pricing</a>
      <a *ngIf="(isLoggedIn | async) === false"
         class="wrapper__nav-link"
         [routerLink]="['/', appRoutes.SignIn]">Sign-in</a>
      <a *ngIf="(isLoggedIn | async)"
         class="wrapper__nav-link"
         (click)="signOut()">Sign-out</a>
      <a *ngIf="(isLoggedIn | async)"
         class="wrapper__nav-link"
         [routerLink]="['/', appRoutes.Dashboard]">
         <div class="mr-2 text-center">Company dashboard</div>
         <img src="/assets/icons/linea/arrows_circle_left.svg"
              class="mt-icon-image--azure mt-icon-image--small">
      </a>
      <a *ngIf="(isLoggedIn | async) === false"
         class="wrapper__nav-link"
         [routerLink]="['/', appRoutes.CompanySignUp]"><b>Create company account</b></a>
    </nav>

    <ng-container *ngIf="isMobile">
      <input type="checkbox" class="navigation__checkbox" id="navi-toggle" [(ngModel)]="expanded">
    
      <label for="navi-toggle" class="navigation__button">
        <span class="navigation__icon"></span>
      </label>
    
      <div class="navigation__background">&nbsp;</div>
    
      <nav class="navigation__nav">
        <ul class="navigation__list">
          <li class="navigation__item" *ngIf="(isLoggedIn | async) === false" (click)="toggleNavbar()">
            <a [routerLink]="['/', appRoutes.CompanySignUp]" class="navigation__link">
              <h3>Create company account</h3>
            </a>
          </li>
          <li class="navigation__item" *ngIf="(isLoggedIn | async) === false" (click)="toggleNavbar()">
            <a [routerLink]="['/', appRoutes.SignIn]" class="navigation__link">
              <h3>Sign-in</h3>
            </a>
          </li>
          <li class="navigation__item" *ngIf="(isLoggedIn | async) === false" (click)="toggleNavbar()">
            <a routerLink="/" fragment="pricing" class="navigation__link">
              <h3>Pricing</h3>
            </a>
          </li>
          <li class="navigation__item" *ngIf="(isLoggedIn | async) === false" (click)="toggleNavbar()">
            <a href="https://mytitle.com" class="navigation__link">
              <h3>Individuals</h3>
            </a>
          </li>
          <li class="navigation__item" *ngIf="(isLoggedIn | async)" (click)="toggleNavbar()">
            <a [routerLink]="['/', appRoutes.Dashboard]" class="navigation__link">
              <h3>Company dashboard</h3>
            </a>
          </li>
          <li class="navigation__item" *ngIf="isLoggedIn | async" (click)="toggleNavbar()">
            <a (click)="signOut()" class="navigation__link">
              <h3>Sign-out</h3>
            </a>
          </li>
        </ul>
      </nav>
    </ng-container>

  </div>
</header>