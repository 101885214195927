import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import { AppModule } from './app/app/app.module';
import { HmrNodeModule } from './app/app/states/models/app.models';
import { ngrxStatePersistance } from './app/app/utils/ngrx-state-persistance';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

const hmrModule = module as HmrNodeModule;

if (environment.hmr) {
  ngrxStatePersistance(bootstrap, hmrModule);
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
