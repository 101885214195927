import { createSelector } from '@ngrx/store';
import { getAuthState } from '../auth.reducers';

export const getCompanyInfoState = createSelector(
  getAuthState,
  state => state.company,
);

export const getCompanyInfo = createSelector(
  getCompanyInfoState,
  state => state.companyInfo,
);

export const getIsGettingCompanyInfo = createSelector(
  getCompanyInfoState,
  state => state.isGettingCompanyInfo,
);

export const getIsPatchingCompanyInfo = createSelector(
  getCompanyInfoState,
  state => state.isPatchingCompanyInfo,
);

export const getIsPatched = createSelector(
  getCompanyInfoState,
  state => state.isPatched,
);

export const getPatchError = createSelector(
  getCompanyInfoState,
  state => state.patchCompanyInfoError,
);

export const getCompanyName = createSelector(
  getCompanyInfoState,
  state => state.companyInfo && state.companyInfo.name,
);

export const getCompanyMembers = createSelector(
  getCompanyInfoState,
  state => state.companyInfo && state.companyInfo.members,
);

export const getPatchSettingsError = createSelector(
  getCompanyInfoState,
  state => state.patchCompanySettingsError,
);

export const getIsPatchingSettings = createSelector(
  getCompanyInfoState,
  state => state.isPatchingCompanySettings,
);

export const getCompanySettings = createSelector(
  getCompanyInfoState,
  state => state.companySettings,
);

export const getIsGettingCompanySettings = createSelector(
  getCompanyInfoState,
  state => state.isGettingCompanySettings,
);

export const getSetCompanyLogoError = createSelector(
  getCompanyInfoState,
  state => state.setCompanyLogoError,
);
