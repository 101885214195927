import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';

@Component({
  selector: 'mt-main-section',
  templateUrl: './main-section.component.html',
  styleUrls: ['../home.component.scss'],
})
export class MainSectionComponent implements OnDestroy {

  public extraSmallScreen = false;
  public appRoutes = AppRoutes;
  private _ngDestroy = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {

    this.breakpointObserver
      .observe('(max-width: 370px)')
      .pipe(takeUntil(this._ngDestroy))
      .subscribe(result => {
        this.extraSmallScreen = result.breakpoints['(max-width: 370px)'];
      });
  }

  public ngOnDestroy() {
    this._ngDestroy.next();
    this._ngDestroy.complete();
  }
}
