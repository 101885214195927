import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { RefreshTokenActions } from '../actions/refreshToken.action';

@Injectable()
export class RefreshTokenEffects {

  @Effect({ dispatch: false })
  public refreshTokenError = this.storeActions.pipe(
    ofType(RefreshTokenActions.REFRESH_TOKEN_ERROR),
    tap(() => {
      this.router.navigate([AppRoutes.SignIn]);
    }),
  );

  constructor(
    private storeActions: Actions,
    private router: Router,
  ) { }
}
