// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
import { TokenInfo } from 'src/app/auth/store/models/token-info.models';
import { MytitleError } from 'src/app/client/models/client.models';
import { SignUpCompanyVerificationParams } from 'src/app/client/models/users.models';

export enum SignUpCompanyVerificationActions {
  SIGNUP_COMPANY_VERIFICATION = '[Auth] Sign-up company verification',
  SIGNUP_COMPANY_VERIFICATION_SUCCESS = '[Auth] Sign-up company verification success',
  SIGNUP_COMPANY_VERIFICATION_ERROR = '[Auth] Sign-up company verification error',
}

export class SignUpCompanyVerification implements Action {
  public readonly type = SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION;
  constructor(public payload: SignUpCompanyVerificationParams) {}
}

export class SignUpCompanyVerificationSuccess implements Action {
  public readonly type = SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_SUCCESS;
  constructor(public payload: TokenInfo) {}
}

export class SignUpCompanyVerificationError implements Action {
  public readonly type = SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_ERROR;
  constructor(public payload: MytitleError[]) {}
}

export type SignUpCompanyVerificationAction =
  SignUpCompanyVerification |
  SignUpCompanyVerificationSuccess |
  SignUpCompanyVerificationError;
