import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { AppState } from 'src/app/app/states/models/app.models';
import { TokenInfo } from 'src/app/auth/store/models/token-info.models';
import { SignInParams } from 'src/app/client/models/users.models';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import { SignIn, SignInActions, SignInError, SignInSuccess, SignInTwoFa } from '../actions/sign-in.actions';
import { getCompanyInfoState } from '../selectors/company.selectors';
import { getSignInFormData } from '../selectors/sign-in.selectors';

@Injectable()
export class SignInEffects {

  @Effect()
  public signIn = this.storeActions.pipe(
    ofType<SignIn>(SignInActions.SIGNIN),
    switchMap(action => this.store.pipe(
      select(getCompanyInfoState),
      first(),
      map(companyInfoState => {
        if (companyInfoState.domainToVerify && companyInfoState.isCompanyDomainVerified) {
          return { ...action.payload, subdomain: companyInfoState.domainToVerify };
        } else return action.payload;
      }),
    )),
    switchMap(payload => this.usersServiceClient.signIn(payload)
      .pipe(
        map(res => {
          const tokenInfo: TokenInfo = {
            expires: new Date(Date.now() + res.expires_in * 1000),
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
          };

          return new SignInSuccess(tokenInfo);
        }),
        catchError((error: HttpErrorResponse) =>
          of(new SignInError(error.error))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  public redirectAfterSignIn = this.storeActions.pipe(
    ofType<SignInSuccess>(SignInActions.SIGNIN_SUCCESS),
    tap(() => this.router.navigate(['/', AppRoutes.Dashboard])),
  );

  @Effect({ dispatch: false })
  public onSignInError = this.storeActions.pipe(
    ofType<SignInError>(SignInActions.SIGNIN_ERROR),
    map(action => action.payload[0]),
    map(error => {
      if (error.code === '2FA_CODE_MISSING') {
        this.router.navigate(['/', AppRoutes.SignIn, AppRoutes.TwoFa]);
      } else if (error.code === 'REGISTRATION_NOT_FINISHED') {
        this.router.navigate(['/', AppRoutes.EmailConfirmation]);
      } else if (error.code === 'NO_ACTIVE_ACCOUNT') {
        this.router.navigate(['/', AppRoutes.SignIn]);
      } else {
        this.router.navigate(['/', AppRoutes.SignIn, AppRoutes.TwoFa]);
      }
    }),
  );

  @Effect()
  public readonly signInWithTwoFA = this.storeActions.pipe(
    ofType<SignInTwoFa>(SignInActions.SIGNIN_TWOFA),
    switchMap(action => {
      return forkJoin([
        this.store.pipe(select(getSignInFormData), first()),
        of(action.payload),
      ]);
    }),
    map(([signInParams, code]) => {
      const params: SignInParams = {
        email: signInParams.email,
        password: signInParams.password,
        code,
      };
      return new SignIn(params);
    }),
  );

  constructor(
    private storeActions: Actions,
    private store: Store<AppState>,
    private router: Router,
    private usersServiceClient: UsersServiceClient,
  ) {}
}
