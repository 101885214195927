import { CompanyInfoEffects } from './effects/company-info.effects';
import { CompanySettingsEffects } from './effects/company-settings.effects';
import { DomainEffects } from './effects/domain.effects';
import { GetGeoIPEffects } from './effects/getGeoIP.effect';
import { GetUserInfoEffects } from './effects/getUserInfo.effect';
import { PersistenceEffects } from './effects/persistence.effect';
import { RefreshTokenEffects } from './effects/refreshToken.effect';
import { ResetPasswordConfirmEffects } from './effects/reset-password-confirm.effects';
import { ResetPasswordEffects } from './effects/reset-password.effects';
import { SignInEffects } from './effects/sign-in.effects';
import { SignOutEffects } from './effects/sign-out.effects';
import { SignUpCompanyVerificationEffects } from './effects/sign-up-company-verification.effects';
import { SignUpCompanyEffects } from './effects/sign-up-company.effects';
import { SignUpEmployeeVerificationEffects } from './effects/sign-up-employee-verification.effects';
import { SignUpInfoEmployeeEffects } from './effects/sign-up-info-employee.effects';

export const authEffects = [
  DomainEffects,
  SignInEffects,
  SignOutEffects,
  SignUpCompanyEffects,
  SignUpCompanyVerificationEffects,
  CompanyInfoEffects,
  CompanySettingsEffects,
  GetGeoIPEffects,
  RefreshTokenEffects,
  PersistenceEffects,
  ResetPasswordEffects,
  ResetPasswordConfirmEffects,
  SignUpInfoEmployeeEffects,
  SignUpEmployeeVerificationEffects,
  GetUserInfoEffects,
];
