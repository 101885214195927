import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CompanySettingsResponse,
  GetAllCoWorkersParams,
  GetAllCoWorkersResponse,
  GetCompanyInfoResponse,
  GetCoWorkerResponse,
  InviteCoWorkersParams,
  InviteCoWorkersResponse,
  PatchCompanyInfoParams,
  PatchCompanyInfoResponse,
  PatchCompanySettingsParams,
  SetCompanyLogoParams,
  VerifyDomainParams,
  VerifyDomainResponse,
} from 'src/app/client/models/users.models';
import { BusinessUserObject } from '../auth/store/models/user.models';
import { ClientModule } from './client.module';
import {
  SignInParams,
  SignUpCompanyParams,
  SignUpCompanyVerificationParams,
  SignUpEmployeeVerificationParams,
  SignUpInfoResponse,
} from './models/users.models';
import { getHttpParams } from './utils';

export enum IDCardType {
  Passport = 'passport',
  DriversLicense = 'drivers_license',
  Personal = 'personal',
}

export interface OAuthValidResponse {
  /** valid JWT */
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
}

export interface UserInfo {
  full_name: string;
  country: string;
  email: string;
  is_eu: boolean;
  two_factor_auth: boolean;
}

export interface NewRegistrationParams {
  email: string;
  full_name: string;
  password: string;
  country: string;
}

export interface VerifyEmailParams {
  code: string;
  email: string;
}

export interface ResendVerificationCodeParams {
  email: string;
  password: string;
}

export interface LoginParams {
  email: string;
  password: string;
  code?: string; // only for users with enabled 2FA
}

export interface ChangePasswordParams {
  old_password: string;
  new_password: string;
}

export interface ChangeUserInfoParams {
  full_name: string;
  country: string;
}

export interface ForgotPasswordRequestParams {
  email: string;
}

export interface ForgotPasswordVerifyParams {
  token: string;
  password: string;
}

export interface ScorePasswordParams {
  password: string;
}

export interface RefreshTokenRequest {
  refresh: string;
}

export interface GeoIP {
  iso_alpha2: string;
  iso_alpha3: string;
  name: string;
  is_eu: boolean;
}

export interface TwoStepAuthenticationResponse {
  provisioning_url?: string;
  png_image?: string;
  backup_codes?: string[];
}

export interface TwoStepAuthenticationParams {
  code: string;
}

export interface GetLimitExceededResponse {
  limit_exceeded: boolean;
}

export interface EnterpriseContactParams {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  phone_number?: string;
  job_title?: string;
  inquiry: string;
}

export interface GetUserDeleteInfoResponse {
  password?: boolean;
  code?: boolean;
}

export interface SubmitUserRemovalParams {
  password?: boolean;
  code?: boolean;
  instant?: boolean;
}

export interface VerifyUserRemovalParams {
  deletion_token: string;
}

export interface VerifyUserRemovalResponse {
  action: string;
}

@Injectable({
  providedIn: ClientModule,
})
export class UsersServiceClient {
  constructor(private http: HttpClient) { }

  /* Business END-POINTS */

  /**
   * Verify company domain name
   */
  public verifyDomain(params: VerifyDomainParams): Observable<VerifyDomainResponse> {
    return this.http.post<VerifyDomainResponse>(`/api/v1/users/business/subdomain-exists`, params);
  }

  /**
   * End-point for signing-up a company
   * Activation link is being sent to entered email
   */
  public signUpCompany(params: SignUpCompanyParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/business/create-company-and-owner`, params);
  }

  /**
   * End-point for additional info about user before
   * verifying and activating his account
   * Mainly for employees to display their company and email
   */
  public signUpInfo(userGuid: string): Observable<SignUpInfoResponse> {
    return this.http.get<SignUpInfoResponse>
      (`/api/v1/users/business/registration-info/${userGuid}`);
  }

  /**
   * End-point for activating company account with token from email
   * Returns OAuthValidResponse -> auto sign-in
   */
  public signUpCompanyVerification(params: SignUpCompanyVerificationParams):
    Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>
      (`/api/v1/users/business/finish-registration/owner`, params);
  }

  /**
   * End-point for activating employee account
   * User has to enter his name and password
   * Returns OAuthValidResponse -> auto sign-in
   */
  public signUpEmployeeVerification(params: SignUpEmployeeVerificationParams):
    Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>
      (`/api/v1/users/business/finish-registration/employee`, params);
  }

  /**
   * End-point for sign-in business users
   */
  public signIn(params: SignInParams): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`/api/v1/users/business/login`, params);
  }

  /**
   * End-point for getting info about company
   * Requires JWT
   */
  public getCompanyInfo(): Observable<GetCompanyInfoResponse> {
    return this.http.get<GetCompanyInfoResponse>(`/api/v1/users/business/company-info`);
  }

  /**
   * End-point for patching company info
   * Requires owner JWT
   */
  public patchCompanyInfo(params: PatchCompanyInfoParams): Observable<PatchCompanyInfoResponse> {
    return this.http.patch<PatchCompanyInfoResponse>(`/api/v1/users/business/company-info`, params);
  }

  /**
   * End-point for getting a co-worker detail
   * Requires owner JWT
   */
  public getCoWorker(userGuid: string): Observable<GetCoWorkerResponse> {
    return this.http.get<GetCoWorkerResponse>(`/api/v1/users/business/user/${userGuid}`);
  }

  /**
   * End-point for getting a co-worker list
   * Requires owner JWT
   */
  public getAllCoWorkers(params: GetAllCoWorkersParams): Observable<GetAllCoWorkersResponse> {
    return this.http.get<GetAllCoWorkersResponse>(
      `/api/v1/users/business/user`,
      { params: getHttpParams(params) },
    );
  }

  /**
   * End-point for inviting a co-worker
   * Requires owner JWT
   */
  public inviteCoWorkers(params: InviteCoWorkersParams): Observable<InviteCoWorkersResponse> {
    return this.http.post<InviteCoWorkersResponse>(`/api/v1/users/business/user`, params);
  }

  /**
   * End-point for deleting a co-worker
   * Requires owner JWT
   */
  public deleteCoWorker(userGuid: string): Observable<void> {
    return this.http.delete<void>(`/api/v1/users/business/user/${userGuid}`);
  }

  /**
   * Reset password (request)
   * Requests a forgot password email with link to reset it.
   */
  public resetPasswordRequest(params: ForgotPasswordRequestParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/business/password/forgot_request`, params);
  }

  /**
   * Reset password (verify)
   * Verifies and sets new password.
   */
  public resetPasswordVerify(params: ForgotPasswordVerifyParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/business/password/forgot_verify`, params);
  }

  /**
   * Get company settings such as automatic certificate downloads and color palette
   */
  public getCompanySettings(): Observable<CompanySettingsResponse> {
    return this.http.get<CompanySettingsResponse>(`/api/v1/users/business/company-settings`);
  }

  /**
   * Patch company settings such as automatic certificate downloads and color palette
   */
  public patchCompanySettings(params: PatchCompanySettingsParams):
    Observable<CompanySettingsResponse> {
      return this.http.patch<CompanySettingsResponse>
        (`/api/v1/users/business/company-settings`, params);
  }

  /**
   * Set company logo
   */
  public setCompanyLogo(params: SetCompanyLogoParams): Observable<CompanySettingsResponse> {

    const formDataParams = new FormData();
    formDataParams.append('logo', params.file);

    return this.http.put<CompanySettingsResponse>
      (`/api/v1/users/business/company-settings/logo`, formDataParams);
  }

  /**
   * Set company second logo
   */
  public setCompanySecondLogo(params: SetCompanyLogoParams): Observable<CompanySettingsResponse> {

    const formDataParams = new FormData();
    formDataParams.append('logo_2', params.file);

    return this.http.put<CompanySettingsResponse>
      (`/api/v1/users/business/company-settings/logo_2`, formDataParams);
  }

  /**
   * Delete company logo
   */
  public deleteCompanyLogo(): Observable<void> {
    return this.http.delete<void>(`/api/v1/users/business/company-settings/logo`);
  }

  /**
   * Delete company second logo
   */
  public deleteCompanySecondLogo(): Observable<void> {
    return this.http.delete<void>(`/api/v1/users/business/company-settings/logo_2`);
  }

  /* Mytitle END-POINTS */

  /**
   * NewRegistration
   * Creates new user account with user name and email. Sends a verification email.
   * Server will always return 204 if the email is in correct form as a protection
   * against user enumeration.
   *
   * Can be used to resend the email verification email (before the email address is
   * verified). Email verification message contains a code that the user needs
   * to enter to verify email.
   */
  public register(params: NewRegistrationParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/register/new`, params);
  }

  /**
   * VerifyEmail
   * Verifies email and setups news password. Both are required.
   */
  public verifyEmail(params: VerifyEmailParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/register/email_verify`, params);
  }

  /**
   * Resend verification code on email. Requires password and email.
   * Can be done only right after registration or when user logs in an not finished registration
   * code is returned, that means that entered email and password is ok.
   */
  public resendVerificationCode(params: ResendVerificationCodeParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/register/resend_email_verify_code`, params);
  }

  /**
   * UserLogin
   * Logs in the user using provided credentials.
   *  https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/
   */
  public login(params: LoginParams): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`/api/v1/users/login`, params);
  }

  /**
   * Retrieve user info
   */
  public getUserInfo(): Observable<UserInfo> {
    return this.http.get<UserInfo>(`/api/v1/users/user_info`);
  }

  /**
   * Changes user info
   */
  public changeUserInfo(params: ChangeUserInfoParams): Observable<UserInfo> {
    return this.http.patch<UserInfo>(`/api/v1/users/user_info`, params);
  }

  /**
   * Refresh token
   */
  public refreshToken(params: RefreshTokenRequest): Observable<OAuthValidResponse> {
    return this.http.post<OAuthValidResponse>(`/api/v1/users/refresh_token`, params);
  }

  /**
   * Forgot password (request)
   * Requests a forgot password email with link to reset it.
   */
  public forgotPasswordRequest(params: ForgotPasswordRequestParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/password/forgot_request`, params);
  }

  /**
   * Forgot password (verify)
   * Verifies and sets new password.
   */
  public forgotPasswordVerify(params: ForgotPasswordVerifyParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/password/forgot_verify`, params);
  }

  /**
   * Score password
   * Checks password security.
   * Responds `204` if password security is sufficient.
   * Responds `400` with error code if not.
   */
  public scorePassword(params: ScorePasswordParams): Observable<void> {
    return this.http.post<void>(`/api/v1/users/password/score`, params);
  }

  /**
   * Get Geo IP address
   */
  public getGeoIP(): Observable<GeoIP> {
    return this.http.get<GeoIP>(`/api/v1/users/geoip`);
  }

  /**
   * Get User Info
   */
  public getUserInfoDetail(): Observable<BusinessUserObject> {
    return this.http.get<BusinessUserObject>(`/api/v1/users/user_info`);
  }

  /**
   * Enable 2-Step Authentication
   */
  public enableTwoStepAuthentication(params?: TwoStepAuthenticationParams):
    Observable<TwoStepAuthenticationResponse> {
    return this.http.post<TwoStepAuthenticationResponse>(`/api/v1/users/2fa`, params);
  }

  /**
   * Disable 2-Step Authentication
   */
  public disableTwoStepAuthentication() {
    return this.http.delete(`/api/v1/users/2fa`);
  }

  /**
   * Checks whether the free plan is exceeded
   */
  public checkLimit(): Observable<GetLimitExceededResponse> {
    return this.http.get<GetLimitExceededResponse>(`/api/v1/mytitle/rate-limit-check`);
  }

  /**
   * Save contact info about companies
   */
  public saveEnterpriceContact(params: EnterpriseContactParams): Observable<void> {
    return this.http.post<void>('/api/v1/users/contact/enterprise', params);
  }

  /**
   * Tells if user has 2FA or password necessary for deletion
   */
  public getUserDeleteInfo(): Observable<GetUserDeleteInfoResponse> {
    return this.http.get<GetUserDeleteInfoResponse>('/api/v1/users/user_deletion/credentials');
  }

  /**
   * Submit user info credentials for account removal
   */
  public submitUserRemoval(params: SubmitUserRemovalParams): Observable<void> {
    return this.http.post<void>('/api/v1/users/user_deletion/new', params);
  }

  /**
   * Verify users with no 2FA
   */
  public verifyUserRemoval(params: VerifyUserRemovalParams): Observable<VerifyUserRemovalResponse> {
    return this.http.post<VerifyUserRemovalResponse>('/api/v1/users/user_deletion/verify', params);
  }
}
