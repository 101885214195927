<section class="section faq">
  <div class="section__heading">
    <div class="mat-subheading-2"
         scrollAnimate
         animationName="fadeInUp">FAQ</div>
  </div>

  <div class="faq__content">
    <div class="row">
      <div class="col-lg">

        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInLeft">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>How safe is your storage?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>Safety is the highest priority.
                  Your file is encrypted by a specific key when it reaches our
                  server and then it is saved. The key is then backed up to another specialized key,
                  this 'safe-key' is kept in storage.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInLeft">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>Who can access my file?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>No one can access the file but our system.
                  Should you request the original file;
                  we must specifically compute access to the key storage to retrieve your key,
                  and then decrypt the file, to deliver it to you.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInLeft">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>Can I keep the key myself?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>Customers at the moment cannot keep the key.
                  This is to safeguard the key from becoming
                  lost and to protect the original file. This feature may change in the future.
                  Please request this feature if it is important to you.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInLeft">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>What is the importance of the certificate?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>Your certificate provides you with the needed
                  information to retrieve the date,
                  time, file fingerprint, and how to extract your data from a blockchain.
                  This allows you to prove that the specific file which was uploaded had been held by you at
                  a specific date and time. Due to the encryption on a blockchain, your data is immutable.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg">
        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInRight">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>Can someone read my personal details stored in the blockchain?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>No!
                  Your personal data in a blockchain is seen as a hash only.
                  There is no way for someone else to retrieve your personal details from a blockchain.
                  It is easy to verify that your unique hash (fingerprint) matches
                  the personal certificate which you have a copy of.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center mb-4"
             scrollAnimate
             animationName="fadeInRight"> 
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-2"><b>Q:</b></span>
                <span class="mat-body-1"><b>What if I need to prove ownership of the file in the court? Do you
                  provide any support?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1 mr-2"><b>A:</b></span>Our certificate proves declared ownership and
                  independent verification of your file.
                  It is up to you to declare that you own or have rights to the file you have submitted to Mytitle.
                  It is up to Mytitle to provide a certificate for the file.
                  We are working closely with international lawyers and are happy to help you.
                  If there is anything we can clarify just get in touch with us at
                  <a class="mt-link" href="mailto:support@mytitle.com">support@mytitle.com</a>.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row justify-content-center"
             scrollAnimate
             animationName="fadeInRight">
          <div class="col-10">
            <div class="d-flex">
              <div class="flex-column">
                <span class="mat-body-1 mr-1"><b>Didn't find the answer you were looking for?</b></span>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-column">
                <div class="mat-caption">
                  <span class="mat-body-1"></span>Ask us directly at <a class="mt-link"
                    href="mailto:support@mytitle.com">support@mytitle.com</a>.
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
