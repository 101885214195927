import { Action } from '@ngrx/store';
import { DomainAction, DomainActions } from 'src/app/auth/store/actions/domain.actions';
import { MytitleError } from 'src/app/client/models/client.models';
import { CompanyInfoAction, CompanyInfoActions } from '../actions/company-info.actions';
import { CompanySettingsAction, CompanySettingsActions } from '../actions/company-settings.actions';
import { Company } from '../models/company.models';

export interface CompanyState {
  isGettingCompanyInfo: boolean;
  companyInfo: Company;
  isPatchingCompanyInfo: boolean;
  isPatched: boolean;
  patchCompanyInfoError: MytitleError[];
  isGettingCompanySettings: boolean;
  companySettings: CompanySettingsState;
  isPatchingCompanySettings: boolean;
  patchCompanySettingsError: MytitleError[];
  isSettingCompanyLogo: boolean;
  setCompanyLogoError: MytitleError[];
  isDeletingCompanyLogo: boolean;
  isCompanyDomainPresent: boolean;
  isCompanyDomainVerified: boolean;
  isVerifyingCompanyDomain: boolean;
  domainToVerify: string;
}

export interface CompanySettingsState {
  primaryColor: string;
  secondaryColor: string;
  logo: string;
  secondLogo: string;
  subdomain: string;
}

export const initialCompanyState: CompanyState = {
  isGettingCompanyInfo: false,
  companyInfo: null,
  isPatchingCompanyInfo: false,
  isPatched: false,
  patchCompanyInfoError: null,
  isGettingCompanySettings: false,
  companySettings: null,
  isPatchingCompanySettings: false,
  patchCompanySettingsError: null,
  isSettingCompanyLogo: false,
  setCompanyLogoError: null,
  isDeletingCompanyLogo: false,
  isCompanyDomainPresent: false,
  isCompanyDomainVerified: false,
  isVerifyingCompanyDomain: false,
  domainToVerify: null,
};

export function companyReducer(
  state = initialCompanyState,
  generic: Action,
): CompanyState {

  const action = generic as CompanyInfoAction | CompanySettingsAction | DomainAction;

  switch (action.type) {

    case CompanyInfoActions.GET_COMPANY_INFO: {
      return {
        ...state,
        isGettingCompanyInfo: true,
        patchCompanyInfoError: null,
      };
    }

    case CompanyInfoActions.GET_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        isGettingCompanyInfo: false,
        companyInfo: {
          guid: action.payload.guid,
          name: action.payload.name,
          vatId: action.payload.vat_id,
          streetAddress: action.payload.street_address,
          members: action.payload.members,
          city: action.payload.city,
          postalCode: action.payload.postal_code,
          state: action.payload.state,
          country: action.payload.country,
        },
      };
    }

    case CompanyInfoActions.PATCH_COMPANY_INFO: {
      return {
        ...state,
        isPatchingCompanyInfo: true,
        patchCompanyInfoError: null,
      };
    }

    case CompanyInfoActions.PATCH_COMPANY_INFO_SUCCESS: {
      return {
        ...state,
        isPatched: true,
        isPatchingCompanyInfo: false,
      };
    }

    case CompanyInfoActions.PATCH_COMPANY_INFO_ERROR: {
      return {
        ...state,
        patchCompanyInfoError: action.payload,
        isPatchingCompanyInfo: false,
      };
    }

    case CompanySettingsActions.GET_COMPANY_SETTINGS: {
      return {
        ...state,
        isGettingCompanySettings: true,
        setCompanyLogoError: null,
      };
    }

    case CompanySettingsActions.GET_COMPANY_SETTINGS_SUCCESS: {
      return {
        ...state,
        isGettingCompanySettings: false,
        companySettings: {
          primaryColor: action.payload.primary_color,
          secondaryColor: action.payload.secondary_color,
          logo: action.payload.logo,
          secondLogo: action.payload.logo_2,
          subdomain: action.payload.subdomain,
        },
      };
    }

    case CompanySettingsActions.PATCH_COMPANY_SETTINGS: {
      return {
        ...state,
        isPatchingCompanySettings: true,
      };
    }

    case CompanySettingsActions.PATCH_COMPANY_SETTINGS_SUCCESS: {
      return {
        ...state,
        isPatched: true,
        isPatchingCompanySettings: false,
        companySettings: {
          primaryColor: action.payload.primary_color,
          secondaryColor: action.payload.secondary_color,
          logo: action.payload.logo,
          secondLogo: action.payload.logo_2,
          subdomain: action.payload.subdomain,
        },
      };
    }

    case CompanySettingsActions.PATCH_COMPANY_SETTINGS_ERROR: {
      return {
        ...state,
        isPatchingCompanySettings: false,
        patchCompanySettingsError: action.payload,
      };
    }

    case CompanySettingsActions.SET_COMPANY_LOGO: {
      return {
        ...state,
        isSettingCompanyLogo: true,
        setCompanyLogoError: null,
      };
    }

    case CompanySettingsActions.SET_COMPANY_LOGO_SUCCESS: {
      return {
        ...state,
        isSettingCompanyLogo: false,
        companySettings: {
          primaryColor: action.payload.primary_color,
          secondaryColor: action.payload.secondary_color,
          logo: action.payload.logo,
          secondLogo: action.payload.logo_2,
          subdomain: action.payload.subdomain,
        },
      };
    }

    case CompanySettingsActions.SET_COMPANY_LOGO_ERROR: {
      return {
        ...state,
        setCompanyLogoError: action.payload,
      };
    }

    case CompanySettingsActions.DELETE_COMPANY_LOGO: {
      return {
        ...state,
        isDeletingCompanyLogo: true,
      };
    }

    case CompanySettingsActions.DELETE_COMPANY_LOGO_SUCCESS: {
      return {
        ...state,
        isDeletingCompanyLogo: false,
        companySettings: {
          ...state.companySettings,
          logo: null,
        },
      };
    }

    case DomainActions.VERIFY_COMPANY_DOMAIN: {
      return {
        ...state,
        domainToVerify: action.payload,
        isCompanyDomainPresent: true,
        isCompanyDomainVerified: false,
        isVerifyingCompanyDomain: true,
      };
    }

    case DomainActions.VERIFY_COMPANY_DOMAIN_SUCCESS: {
      return {
        ...state,
        isCompanyDomainVerified: action.payload.subdomain_exists,
        isVerifyingCompanyDomain: false,
      };
    }

    case DomainActions.VERIFY_COMPANY_DOMAIN_ERROR: {
      return {
        ...state,
        isVerifyingCompanyDomain: false,
      };
    }

    default: return state;
  }
}
