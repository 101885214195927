import { HttpParams } from '@angular/common/http';

/**
 * Helper function to get HttpParams used in GET requests
 * which have to be strictly in [key of string]: string shape.
 * @param params any object having properties with values.
 */
export function getHttpParams(params: any): HttpParams {
  if (!params) return null;

  let queryParams = new HttpParams();
  Object.entries(params).forEach(([key, value]: [string, any]) => {
    if (value !== undefined) {
      if (typeof value === 'string') queryParams = queryParams.set(key, value);
      else queryParams = queryParams.set(key, JSON.stringify(value));
    }
  });

  return queryParams;
}
