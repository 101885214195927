import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import {
  SignUpCompany,
  SignUpCompanyActions,
  SignUpCompanyError,
  SignUpCompanySuccess,
} from '../actions/sign-up-company.actions';

@Injectable()
export class SignUpCompanyEffects {

  @Effect()
  public signUpCompany = this.storeActions.pipe(
    ofType<SignUpCompany>(SignUpCompanyActions.SIGNUP_COMPANY),
    switchMap(action => this.usersServiceClient.signUpCompany(action.payload)
      .pipe(
        map(() => new SignUpCompanySuccess()),
        catchError((error: HttpErrorResponse) => of(new SignUpCompanyError(error.error))),
      ),
    ),
  );

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {}
}
