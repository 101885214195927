import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from './models/app.models';

export const appReducers: ActionReducerMap<AppState> = {
  router: routerReducer,
};

// tslint:disable-next-line:array-type
export const metaReducers: MetaReducer<AppState>[] = [];
