import {
  animate,
  animateChild,
  AnimationMetadata,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const leftToRight: AnimationMetadata[] = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      right: 0,
      width: '100%',
    }),
  ], { optional: true }),
  query(':enter', [
    style({ right: '-100%' }),
  ]),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('500ms ease-out', style({ right: '100%' })),
    ], { optional: true }),
    query(':enter', [
      animate('500ms ease-out', style({ right: '0%' })),
    ]),
  ]),
  query(':enter', animateChild()),
];

export const rightToLeft: AnimationMetadata[] = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
    }),
  ], { optional: true }),
  query(':enter', [
    style({ left: '-100%' }),
  ]),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('500ms ease-out', style({ left: '100%' })),
    ], { optional: true }),
    query(':enter', [
      animate('500ms ease-out', style({ left: '0%' })),
    ]),
  ]),
  query(':enter', animateChild()),
];

export const routingAnimations =
  trigger('routeAnimations', [
    // Landing
    transition('Landing => *', leftToRight),

    // Sign-up
    transition('Signup => Signin', leftToRight),
    transition('Signup => ResetPassword', leftToRight),
    transition('Signup => Dashboard', leftToRight),
    transition('Signup => Landing', rightToLeft),

    // Sign-in
    transition('Signin => Signup', rightToLeft),
    transition('Signin => Landing', rightToLeft),
    transition('Signin => Dashboard', leftToRight),
    transition('Signin => ResetPassword', leftToRight),

    // Reset Password
    transition('ResetPassword => Landing', rightToLeft),
    transition('ResetPassword => Signin', rightToLeft),
    transition('ResetPassword => Signup', rightToLeft),

    // Dashboard
    transition('Dashboard => Signin', rightToLeft),
    transition('Dashboard => Signup', rightToLeft),
    transition('Dashboard => Landing', rightToLeft),
    transition('Dashboard => Error', rightToLeft),
    transition('Dashboard => ResetPassword', rightToLeft),

    // Error
    transition('Error => Landing', rightToLeft),
    transition('Error => Dashboard', leftToRight),
  ]);
