import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppRoutes } from 'src/app/app/app.routes.misc';
import { NotificationService } from 'src/app/app/services/notification.service';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import { DashboardRoutes } from 'src/app/pages/dashboard/dashboard.routes.misc';
import { ErrorHelperService } from 'src/app/shared/services/error-helper.service';
import {
  CompanyInfoActions,
  GetCompanyInfo,
  GetCompanyInfoSuccess,
  PatchCompanyInfo,
  PatchCompanyInfoError,
  PatchCompanyInfoSuccess,
} from '../actions/company-info.actions';
import { LoadTokenActions, LoadTokenSuccess } from '../actions/loadToken.action';
import { SignInActions, SignInSuccess } from '../actions/sign-in.actions';
import {
  SignUpCompanyVerificationActions,
  SignUpCompanyVerificationSuccess,
} from '../actions/sign-up-company-verification.actions';

@Injectable()
export class CompanyInfoEffects {

  @Effect()
  public listenToAuthActions = this.storeActions.pipe(
    ofType<
      LoadTokenSuccess |
      SignInSuccess |
      SignUpCompanyVerificationSuccess
    >(
      LoadTokenActions.LOAD_TOKEN_SUCCESS,
      SignInActions.SIGNIN_SUCCESS,
      SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_SUCCESS,
    ),
    map(() => new GetCompanyInfo()),
  );

  @Effect()
  public getCompanyInfo = this.storeActions.pipe(
    ofType<GetCompanyInfo>(CompanyInfoActions.GET_COMPANY_INFO),
    switchMap(() => this.usersServiceClient.getCompanyInfo()
      .pipe(
        map(res => new GetCompanyInfoSuccess(res)),
        this.errorHelperService.raiseError(),
      ),
    ),
  );

  @Effect()
  public patchCompanyInfo = this.storeActions.pipe(
    ofType<PatchCompanyInfo>(CompanyInfoActions.PATCH_COMPANY_INFO),
    switchMap(action => this.usersServiceClient
      .patchCompanyInfo(action.payload)
      .pipe(
        map(res => new PatchCompanyInfoSuccess(res)),
        catchError((error: HttpErrorResponse) =>
          of(new PatchCompanyInfoError(error.error))),
      ),
    ),
  );

  @Effect({ dispatch: false })
  public readonly redirectOnSuccess = this.storeActions.pipe(
    ofType<PatchCompanyInfoSuccess>(CompanyInfoActions.PATCH_COMPANY_INFO_SUCCESS),
    tap(() => [
      this.notificationService.showSuccess('Billing information was changed!'),
      this.router
        .navigate(['', AppRoutes.Dashboard, DashboardRoutes.Billing]),
      ],
    ),
  );

  constructor(
    private storeActions: Actions,
    private router: Router,
    private errorHelperService: ErrorHelperService,
    private usersServiceClient: UsersServiceClient,
    private notificationService: NotificationService,
  ) {}
}
