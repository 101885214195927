import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  SignUpCompanyVerificationAction,
  SignUpCompanyVerificationActions,
} from '../actions/sign-up-company-verification.actions';

export interface SignUpCompanyVerificationState {
  isVerifying: boolean;
  isVerified: boolean;
  error: MytitleError[];
}

const signUpCompanyVerificationInitialState: SignUpCompanyVerificationState = {
  isVerifying: false,
  isVerified: false,
  error: null,
};

export function signUpCompanyVerificationReducer(
  state = signUpCompanyVerificationInitialState,
  generic: Action,
): SignUpCompanyVerificationState {

  const action = generic as SignUpCompanyVerificationAction;

  switch (action.type) {
    case SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION: {
      return {
        error: null,
        isVerifying: true,
        isVerified: false,
      };
    }

    case SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isVerifying: false,
        isVerified: true,
      };
    }

    case SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION_ERROR: {
      return {
        ...state,
        isVerifying: false,
        error: action.payload,
      };
    }

    default: return state;
  }
}
