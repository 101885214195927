import { Action } from '@ngrx/store';
import { MytitleError } from 'src/app/client/models/client.models';
import {
  SignUpEmployeeVerificationAction,
  SignUpEmployeeVerificationActions } from '../actions/sign-up-employee-verification.actions';

export interface SignUpEmployeeVerificationState {
  isVerifying: boolean;
  isVerified: boolean;
  error: MytitleError[];
}

const signUpEmployeeVerificationInitialState: SignUpEmployeeVerificationState = {
  isVerifying: false,
  isVerified: false,
  error: null,
};

export function signUpEmployeeVerificationReducer(
  state = signUpEmployeeVerificationInitialState,
  generic: Action,
): SignUpEmployeeVerificationState {

  const action = generic as SignUpEmployeeVerificationAction;

  switch (action.type) {
    case SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION: {
      return {
        error: null,
        isVerifying: true,
        isVerified: false,
      };
    }

    case SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_SUCCESS: {
      return {
        ...state,
        isVerifying: false,
        isVerified: true,
      };
    }

    case SignUpEmployeeVerificationActions.SIGNUP_EMPLOYEE_VERIFICATION_ERROR: {
      return {
        ...state,
        isVerifying: false,
        isVerified: false,
        error: action.payload,
      };
    }

    default: return state;
  }
}
