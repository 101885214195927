import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import {
  SignUpInfoEmployee,
  SignUpInfoEmployeeActions,
  SignUpInfoEmployeeError,
  SignUpInfoEmployeeSuccess } from '../actions/sign-up-info-employee.actions';

@Injectable()
export class SignUpInfoEmployeeEffects {

  @Effect()
  public signUpInfoEmployee = this.storeActions.pipe(
    ofType<SignUpInfoEmployee>(SignUpInfoEmployeeActions.SIGNUP_INFO_EMPLOYEE),
    switchMap(action => this.usersServiceClient.signUpInfo(action.payload)
      .pipe(
        map(response => new SignUpInfoEmployeeSuccess(response)),
        catchError((error: HttpErrorResponse) => of(new SignUpInfoEmployeeError(error.error))),
      ),
    ),
  );

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {}
}
