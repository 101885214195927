export enum DashboardRoutes {
  // Welcome pages
  Welcome = 'welcome',
  WelcomeInvite = 'invite',

  // Co-workers routes
  CoWorkers = 'co-workers',
  AddCoWorkers = 'invite',

  // Billing routes
  Billing = 'billing',
  BillingInfo = 'info',
  Purchase = 'purchase',
  Confirmation = 'confirmation',
  InvoiceList = 'invoices',

  // Documents
  Documents = 'documents',
  File = 'file',
  Folder = 'folder',
  NewFolder = 'new',
  Shared = 'shared',

  // Security
  Security = 'security',
  Groups = 'groups',
  CreateGroup = 'create',
  Access = 'access',
  CreateAccess = 'create',

  // Workspace routes
  Workspaces = 'workspaces',
  CreateWorkspace = 'create',

  // Settings routes
  Settings = 'settings',
  Logo = 'logo',
  SecondLogo = 'second-logo',
  Preferences = 'preferences',
  TwoFactorVerification = 'two-fa',
  TwoFactorFinished = 'two-fa-finish',
}
