import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { companyReducer, CompanyState } from './reducers/company.reducer';
import { resetPasswordReducer, ResetPasswordState } from './reducers/reset-password.reducer';
import { signInReducer, SignInState } from './reducers/sign-in.reducer';
import {
  signUpCompanyVerificationReducer,
  SignUpCompanyVerificationState,
} from './reducers/sign-up-company-verifiation.reducer';
import { signUpCompanyReducer, SignUpCompanyState } from './reducers/sign-up-company.reducer';
import {
  signUpEmployeeVerificationReducer,
  SignUpEmployeeVerificationState } from './reducers/sign-up-employee-verification.reducer';
import {
  signUpInfoEmployeeReducer,
  SignUpInfoEmployeeState } from './reducers/sign-up-info-employee.reducer';
import { userReducer, UserState } from './reducers/user.reducer';

export interface AuthState {
  signIn: SignInState;
  signUpCompany: SignUpCompanyState;
  signUpCompanyVerification: SignUpCompanyVerificationState;
  signUpInfoEmployee: SignUpInfoEmployeeState;
  signUpEmployeeVerification: SignUpEmployeeVerificationState;
  resetPassword: ResetPasswordState;
  user: UserState;
  company: CompanyState;
}

export const authReducers: ActionReducerMap<AuthState> = {
  signIn: signInReducer,
  signUpCompany: signUpCompanyReducer,
  signUpCompanyVerification: signUpCompanyVerificationReducer,
  signUpInfoEmployee: signUpInfoEmployeeReducer,
  signUpEmployeeVerification: signUpEmployeeVerificationReducer,
  resetPassword: resetPasswordReducer,
  user: userReducer,
  company: companyReducer,
};

export const authStoreName = 'auth';

export const getAuthState = createFeatureSelector<AuthState>(authStoreName);
