<footer>
  <a routerLink="/"
     class="footer__logo-box"
     scrollAnimate
     animationName="fadeInUp">
    <img src="/assets/icons/my-title-business-logo-white.svg" class="footer__logo" alt="logo">
  </a>

  <div class="footer__link-box">
    <div class="footer__link-column">
      <a routerLink="/"
         fragment="how-it-works"
         class="footer__link footer__link--right"
         scrollAnimate
         animationName="fadeInLeft">How it works</a>
      <a routerLink="/"
         fragment="pricing"
         class="footer__link footer__link--right"
         scrollAnimate
         animationName="fadeInLeft">Pricing</a>
      <a [routerLink]="['/', appRoutes.CompanySignUp]"
         class="footer__link footer__link--right"
         scrollAnimate
         animationName="fadeInLeft">Create company account</a>
      <a [routerLink]="['/', appRoutes.SignIn]"
         class="footer__link footer__link--right"
         scrollAnimate
         animationName="fadeInLeft">Log In</a>
      <a href="https://mytitle.com/"
         class="footer__link footer__link--right"
         scrollAnimate
         animationName="fadeInLeft">Mytitle for Individuals</a>
      <a href="https://www.iubenda.com/privacy-policy/76547409/cookie-policy"
         class="footer__link footer__link--right"
         target="_blank"
         rel="noreferrer"
         scrollAnimate
         animationName="fadeInLeft">Cookie Policy</a>
    </div>

    <div class="footer__link-column">
      <a [routerLink]="['/', appRoutes.About]"
         class="footer__link"
         scrollAnimate
         animationName="fadeInRight">About us</a>
      <a [routerLink]="['/', appRoutes.About, appRoutes.Contact]"
         class="footer__link"
         scrollAnimate
         animationName="fadeInRight">Contact</a>
      <a href="https://mytitle.com/about/legal"
         class="footer__link"
         scrollAnimate
         animationName="fadeInRight">Legal Statement</a>
      <a href="https://mytitle.com/about/terms"
         class="footer__link"
         scrollAnimate
         animationName="fadeInRight">Terms of Use</a>
      <a href="https://mytitle.com/about/gdpr"
         class="footer__link"
         scrollAnimate
         animationName="fadeInRight">GDPR Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/76547409"
         class="footer__link"
         target="_blank"
         rel="noreferrer"
         scrollAnimate
         animationName="fadeInRight">Privacy Policy</a>
    </div>
  </div>

  <div class="footer__socials-box"
       scrollAnimate
       animationName="fadeInUp">
    <a href="https://www.facebook.com/mytitleforme/" target="_blank" class="" data-cy="facebook" rel="noreferrer">
      <svg class="footer__socials-item">
        <use xlink:href="/assets/img/sprite.svg#facebook-link"></use>
      </svg>
    </a>

    <a href="https://www.linkedin.com/company/mytitle/about/" target="_blank" data-cy="linkedin" rel="noreferrer">
      <svg class="footer__socials-item">
        <use xlink:href="/assets/img/sprite.svg#linkedin-link"></use>
      </svg>
    </a>
  </div>

  <div class="footer__date"
       scrollAnimate
       animationName="fadeInUp">
    Mytitle &copy; {{ currentDate | date:"yyyy" }}
  </div>
</footer>