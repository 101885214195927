import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { PublicFooterModule } from 'src/app/partials/public-footer/public-footer.module';
import { PublicNavbarModule } from 'src/app/partials/public-navbar/public-navbar.module';
import {
  AnimationOnScrollDirective,
} from '../directives/animation-on-scroll.directive';
import { AboutUsSectionComponent } from './about-us-section/about-us-section.component';
import { FaqSectionComponent } from './faq-section/faq-section.component';
import { FeaturesSectionComponent } from './features-section/features-section.component';
import { HomeComponent } from './home.component';
import { HowItWorksSectionComponent } from './how-it-works-section/how-it-works-section.component';
import { MainSectionComponent } from './main-section/main-section.component';
import { PricingSectionComponent } from './pricing-section/pricing-section.component';

@NgModule({
  declarations: [
    HomeComponent,
    MainSectionComponent,
    HowItWorksSectionComponent,
    FeaturesSectionComponent,
    PricingSectionComponent,
    FaqSectionComponent,
    AboutUsSectionComponent,
    AnimationOnScrollDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatIconModule,
    PublicNavbarModule,
    PublicFooterModule,
  ],
})
export class HomeModule {}
