import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { TokenInfo } from 'src/app/auth/store/models/token-info.models';
import { UsersServiceClient } from 'src/app/client/users-service.client';
import {
  SignUpCompanyVerification,
  SignUpCompanyVerificationActions,
  SignUpCompanyVerificationError,
  SignUpCompanyVerificationSuccess,
} from '../actions/sign-up-company-verification.actions';

@Injectable()
export class SignUpCompanyVerificationEffects {

  @Effect()
  public verifyCompany = this.storeActions.pipe(
    ofType<SignUpCompanyVerification>(SignUpCompanyVerificationActions.SIGNUP_COMPANY_VERIFICATION),
    switchMap(action => this.usersServiceClient.signUpCompanyVerification(action.payload)
      .pipe(
        map(res => {
          const tokenInfo: TokenInfo = {
            expires: new Date(Date.now() + res.expires_in * 1000),
            accessToken: res.access_token,
            refreshToken: res.refresh_token,
          };

          return new SignUpCompanyVerificationSuccess(tokenInfo);
        }),
        catchError((error: HttpErrorResponse) =>
          of(new SignUpCompanyVerificationError(error.error))),
      ),
    ),
  );

  constructor(
    private storeActions: Actions,
    private usersServiceClient: UsersServiceClient,
  ) {}
}
