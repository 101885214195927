/**
 * Enum of paths for app routes.
 */
export enum AppRoutes {
  CompanySignUp = 'sign-up-company',
  EmployeeSignUp = 'sign-up',
  Dashboard = 'dashboard',
  SignIn = 'sign-in',
  TwoFa = 'two-fa',
  About = 'about',
  Contact = 'contact',
  CreditBundleChoice = 'security-pricing',
  DocumentCredentials = 'document-credentials',
  DocumentProgress = 'document-progress',
  FolderUploadConfirmation = 'folder-upload-confirmation',
  FolderUploadCredentials = 'folder-upload-credentials',
  FolderUploadProgress = 'folder-upload-progress',
  DeletionDocumentsConfirm = 'deletion',
  EmailConfirmation = 'email-confirmation',
  Error = 'error',
  MyDocuments = 'my-documents',
  CreditOrderSummary = 'credit-order-summary',
  PaymentChoice = 'payment-choice',
  PaymentConfirmation = 'payment-confirmation',
  ResetPassword = 'reset-password-request',
  ResetPasswordConfirm = 'reset-password',
  Verification = 'verification',
  Settings = 'settings',
  NotFound = '**',
}
