export const takenDomainNames: string[] = [
  'mytitle',
  'com',
  'www',
  'dev',
  'dev2',
  'business',
  'localhost',
];

export default function parseDomainName(hostname: string): string {
  const companyDomainName = hostname
    .split('.')
    .filter(x => !takenDomainNames.includes(x));
  return companyDomainName[0];
}
